/*//////////////////////////////////////////////////SPECIFIC*/

body,
select {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* loading spinner */
.blade {
  position: absolute;
  left: .4629em;
  bottom: 0;
  width: .074em;
  height: .2777em;
  border-radius: .0555em;
  background-color: transparent;
  transform-origin: center -.2222em;
  animation: blade-fade 1.2s infinite linear;
}

.blade-1 {
  animation-delay: .1s;
  transform: rotate(30deg);
}

.blade-2 {
  animation-delay: .2s;
  transform: rotate(60deg);
}

.blade-3 {
  animation-delay: .3s;
  transform: rotate(90deg);
}

.blade-4 {
  animation-delay: .4s;
  transform: rotate(120deg);
}

.blade-5 {
  animation-delay: .5s;
  transform: rotate(150deg);
}

.blade-6 {
  animation-delay: .6s;
  transform: rotate(180deg);
}

.blade-7 {
  animation-delay: .7s;
  transform: rotate(210deg);
}

.blade-8 {
  animation-delay: .8s;
  transform: rotate(240deg);
}

.blade-9 {
  animation-delay: .9s;
  transform: rotate(270deg);
}

.blade-10 {
  animation-delay: 1s;
  transform: rotate(300deg);
}

.blade-11 {
  animation-delay: 1.1s;
  transform: rotate(330deg);
}

.blade-12 {
  animation-delay: 1.2s;
  transform: rotate(360deg);
}

@keyframes blade-fade {
  0% {
    background-color: #fcfcfc;
  }

  100% {
    background-color: transparent;
  }
}

.rotate {
  transform: rotate(-90deg);
}

.tag {
  display: block;
  height: 1.5em;
  user-select: none;
}

.shine {
  box-shadow: inset 0 0 0em rgba(255, 255, 255, .5);
  transition: box-shadow 0.3s ease-in-out;
}

.shine:hover,
.disabled {
  box-shadow:
    inset 0 0 .25em 3px rgba(255, 255, 255, .5),
    inset -.125em -.125em .25em .25em rgba(0, 0, 0, .3),
    inset 0 0 .25em .125em rgba(255, 255, 255, 0);
}

.shine:focus {
  box-shadow: inset .1em .1em .3em 0 rgba(0, 0, 0, 1);
}

/*//////////////////////////////////////////////////GENERAL*/

* {
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  outline: none;
}

html,
body {
  position: fixed;
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  color: #000000;
  background-color: #000000;
}

/* :focus { text-decoration: underline; } */

a {
  text-decoration: none;
}

a[disabled] {
  pointer-events: none;
}

a:focus,
a:hover {
  text-decoration: underline;
}

button {
  font-family: inherit;
  color: inherit;
  border: none;
}

h1 {
  line-height: 1;
}

input,
textarea {
  display: inline;
  appearance: none;
  box-shadow: none;
  outline: none;
  outline-offset: 2px;
  border: none;
}

textarea {
  resize: none;
  height: 1em;
}

input {
  vertical-align: middle;
}

input[type="submit"]:hover {
  background-color: #151515;
  color: #fcfcfc;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fcfcfc inset;
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}

::placeholder {
  color: lightgrey;
}

select {
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

video,
img {
  display: block;
}

.p-0 {
  padding: 0;
}

.p-q {
  padding: .125em;
}

.pt-q {
  padding-top: .125em;
}

.pr-q {
  padding-right: .125em;
}

.pb-q {
  padding-bottom: .125em;
}

.pl-q {
  padding-left: .125em;
}

.p-w {
  padding: .25em;
}

.pt-w {
  padding-top: .25em;
}

.pr-w {
  padding-right: .25em;
}

.pb-w {
  padding-bottom: .25em;
}

.pl-w {
  padding-left: .25em;
}

.p-h {
  padding: .5em;
}

.pt-h {
  padding-top: .5em;
}

.pr-h {
  padding-right: .5em;
}

.pb-h {
  padding-bottom: .5em;
}

.pl-h {
  padding-left: .5em;
}

.p-1 {
  padding: 1em;
}

.pt-1 {
  padding-top: 1em;
}

.pr-1 {
  padding-right: 1em;
}

.pb-1 {
  padding-bottom: 1em;
}

.pl-1 {
  padding-left: 1em;
}

.m-0 {
  margin: 0;
}

.m-h {
  margin: .5em;
}

.mt-h {
  margin-top: .5em;
}

.mr-h {
  margin-right: .5em;
}

.mb-h {
  margin-bottom: .5em;
}

.ml-h {
  margin-left: .5em;
}

.m-1 {
  margin: 1em;
}

.mt-1 {
  margin-top: 1em;
}

.mr-1 {
  margin-right: 1em;
}

.mb-1 {
  margin-bottom: 1em;
}

.ml-1 {
  margin-left: 1em;
}

.center-auto {
  margin-left: auto;
  margin-right: auto;
}

.center-percent {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.width-screen {
  width: 100vw;
}

.width {
  width: 100%;
}

.width-auto {
  width: auto;
}

.width-0 {
  width: 0;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-50 {
  width: 50%;
}

.width-70 {
  width: 70%;
}

.width-80 {
  width: 80%;
}

.min-width {
  min-width: 100%;
}

.max-width {
  max-width: 100%;
}

.height {
  height: 100%;
}

.height-screen {
  height: 100vh;
}

.min-height {
  min-height: 100%;
}

.min-height-screen {
  min-height: 100vh;
}

.max-height {
  max-height: 100%;
}

@media screen and (max-width: 1024px) {
  .width-sm {
    width: 100%;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-between {
  justify-content: space-between;
}

.flex-align-x-left {
  justify-content: flex-start;
}

.flex-align-x-center {
  justify-content: center;
}

.flex-align-x-right {
  justify-content: flex-end;
}

.flex-align-y-top {
  align-items: flex-start;
}

.flex-align-y-center {
  align-items: center;
}

.flex-align-y-bottom {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-a {
  flex-basis: 0;
}

.flex-0 {
  flex-basis: 0%;
}

.flex-15 {
  flex-basis: 15%;
}

.flex-20 {
  flex-basis: 20%;
}

.flex-25 {
  flex-basis: 25%;
}

.flex-30 {
  flex-basis: 30%;
}

.flex-35 {
  flex-basis: 35%;
}

.flex-40 {
  flex-basis: 40%;
}

.flex-45 {
  flex-basis: 45%;
}

.flex-50 {
  flex-basis: 50%;
}

.flex-55 {
  flex-basis: 55%;
}

.flex-60 {
  flex-basis: 60%;
}

.flex-65 {
  flex-basis: 65%;
}

.flex-70 {
  flex-basis: 70%;
}

.flex-75 {
  flex-basis: 75%;
}

.flex-80 {
  flex-basis: 80%;
}

.flex-85 {
  flex-basis: 85%;
}

.flex-90 {
  flex-basis: 90%;
}

.flex-95 {
  flex-basis: 95%;
}

.flex-100 {
  flex-basis: 100%;
}

.flex-noshrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

@media screen and (max-width: 1024px) {
  .flex-sm {
    flex-basis: 100%;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.top {
  top: 0;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

.left {
  left: 0;
}

.hidden {
  overflow: hidden;
}

.auto {
  overflow: auto;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-y-nobar {
  box-sizing: content-box;
  padding-right: 17px;
}

::-webkit-scrollbar {
  display: none;
}

.block {
  display: block;
}

.none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.nopointer {
  pointer-events: none;
}

.nopointer>* {
  pointer-events: auto;
}

.noselect {
  user-select: none;
}

.t-center {
  text-align: center;
}

.t-nowrap {
  white-space: nowrap;
}

.t-wrap {
  overflow-wrap: break-word;
}

.t-ellipsis {
  text-overflow: ellipsis;
}

.t-shadow,
.t-shadow>p {
  text-shadow: 2px 2px 4px #151515;
}

.t-underline {
  text-decoration: underline;
}

.t-s {
  font-size: .6em;
}

.shadow {
  box-shadow: 0 0 .5em rgba(0, 0, 0, 1);
}

.will-change {
  will-change: transform;
}

.radius {
  border-radius: .6em;
}

.appear {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}

.blend {
  mix-blend-mode: difference;
}